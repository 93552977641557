import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/lejek-sprzedazy-tlo.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    kanban: file(relativePath: { eq: "crm-kanban-questy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    konwersja: file(relativePath: { eq: "crm-konwersja-lejka.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Specrm = ({ data }) => {
  return (
    <Artykul
      title="Lejek sprzedażowy – wsparcie sprzedaży i&nbsp;działu handlowego"
      keywords={["lejek sprzedaży"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Lejek sprzedażowy – wsparcie sprzedaży i działu handlowego"
      metaTitle="Lejek sprzedażowy – wsparcie sprzedaży i działu handlowego"
      metaDescription="Lejek sprzedaży w systemie CRM – czym jest, do czego służy i jak go wykorzystywać? • Zalety organizacji procesów • Lejek w widoku kanban"
    >
      <p style={{
          paddingTop: 30,
          paddingBottom: 15,
        }}>
        Dla każdej firmy, niezależnie od tego, w jakiej branży operuje,
        najważniejsza jest sprzedaż. Dlatego też warto dołożyć wszelkich starań,
        aby swój proces sprzedaży nieustannie analizować, poprawiać i
        udoskonalać. Jednym ze sposobów na to jest poręczna wizualizacja, jaką
        jest lejek sprzedażowy. W niniejszym materiale pochylimy się nad procesami
        sprzedażowymi oraz tym, jak w ich organizacji i wsparciu sprzedaży może nam pomóc wspomniany
        lejek.
      </p>
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/8lvf_iu71T8"
        allowFullScreen
        ratio="16:9"
      />
      <h2 style={{
          paddingTop: 25,
          paddingBottom: 15,
        }}>Proces sprzedaży – co to takiego?</h2>
      <p>
        Cały proces sprzedaży można opisać jako podzieloną na etapy drogę, którą
        pokonuje nasz klient. Jeżeli klient znajdzie się na końcu tej ścieżki,
        dochodzi do transakcji. Jeżeli z kolei zaginie gdzieś po drodze, nasza szansa na
        sprzedaż zostaje utracona.
      </p>

      <p>
        Proces sprzedaży powinien być odpowiedzią na zwyczaje zakupowe klienta.
        Dlatego też najlepszą podstawą do utworzenia procesu sprzedaży jest
        dokładne poznanie kroków, które podejmuje klient, aby kupić nasz
        produkt.
      </p>

      <p>
        Należy podkreślić, że w dzisiejszych czasach proces zakupowy klientów
        jest o wiele bardziej skomplikowany niż to miało miejsce w&nbsp;przeszłości.
        Przy obecnej liczbie opcji oraz możliwości, ścieżki klientów mogą
        przybierać bardzo różne formy. Dlatego właśnie narzędzia, które
        umożliwiają wizualizację tych procesów i&nbsp;wsparcie sprzedaży, okazują się tak pomocne.
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz usprawnić proces sprzedaży w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas i zyskaj dostęp do bezpłatnej 30-dniowej wersji systemu CRM</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wesprzeć swój proces sprzedaży
            </Link>
          </div>
          </div>
    </section>
      <h2>Czy istnieje idealny proces sprzedaży?</h2>
      <p>
        Odpowiedź na to pytanie nie jest tak trudna, jak by się mogło wydawać – 
        nie ma bowiem jednego słusznego
        wzoru, na którym powinien bazować idealny proces sprzedaży. Całe to
        zagadnienie jest zbyt złożone, aby można było się pokusić o&nbsp;wskazanie
        jednej słusznej drogi wsparcia sprzedaży i działu handlowego. 
        Kształt procesu sprzedaży powinien zależeć od
        kilku czynników, wśród których najważniejszymi są nasze produkty, nasz
        target oraz wykorzystywane kanały sprzedażowe.
      </p>

      <p>
        Jeżeli produkt jest powtarzalny i niespecjalnie skomplikowany, to
        zazwyczaj mamy do czynienia ze sprzedażą “transakcyjną”. W tego typu
        sprzedaży najważniejszymi czynnikami są najczęściej proste parametry –
        cena, czas dostawy czy warunki gwarancji.
      </p>

      <p>
        Gdy produkt jest bardziej złożony, mówimy o&nbsp;sprzedaży “relacyjnej”.
        Tutaj bardzo ważny jest przede wszystkim handlowiec o odpowiednich kompetencjach.
      </p>

      <h2>Różne procesy sprzedaży w B2B i B2C</h2>
      <p>
        Transakcje B2B i B2C różnią się od siebie w&nbsp;znaczącym stopniu, dlatego
        też procesy sprzedaży mogą wyglądać w obu przypadkach inaczej. W dużym
        uproszczeniu, różnice polegają głównie na motywacji kupującego oraz długości
        cyklu sprzedaży. W przypadku sektora B2B motywatorem jest zazwyczaj
        logika, a&nbsp;cykl sprzedaży jest długi. W B2C klient kieruje się raczej
        emocjami, a cykl jest znacznie krótszy – wsparcie sprzedaży tego rodzaju 
        przyjmuje więc znacznie inne formy.
      </p>

      <p>
        Niezależnie od tego, czy operujemy w B2B czy też w B2C, warto opracować
        swoje procesy sprzedaży i&nbsp;ustanowić je uniwersalnymi dla całej firmy.
      </p>

      <h2>Do czego służy lejek sprzedaży?</h2>
      <p>
        Lejek sprzedaży (czy też „lejek sprzedażowy”) jest prostym sposobem na wizualne przedstawienie
        procesów sprzedaży firmy. Swoją nazwę zawdzięcza on sposobowi, w jaki najczęściej
        jest prezentowany, ale również temu, że jest dobrą metaforą na
        określenie przepływu klientów pomiędzy poszczególnymi etapami ścieżki
        sprzedaży. Najwięcej klientów jest “na górze” lejka sprzedażowego, a wraz z&nbsp;przechodzeniem 
        do kolejnych etapów, ich liczba się zmniejsza.
      </p>

      <p style={{
          paddingBottom: 25,
        }}>
        W systemie CRM lejek sprzedaży może być przedstawiony, na przykład, w
        postaci tablicy kanban. W tym przykładzie wspierająca sprzedaż ścieżka zakupowa, która
        prowadzi klienta do transakcji, jest podzielona jest na cztery fazy.
      </p>

      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.kanban]}
        alts={["Lejek sprzedaży w widoku kanban w systemie crm."]}
      />
      <p style={{
          paddingTop: 25,
        }}>
        <strong>Nowa szansa</strong> – pierwszy etap lejka sprzedażowego, który 
        przedstawia klientów zakwalifikowanych
        jako potencjalni na produkty lub usługi firmy. Na tym etapie handlowiec
        powinien dążyć do ustalenia wstępnych oczekiwań klienta, co z kolei
        pozwoli mu określić, czy firma posiada dla niego odpowiedni produkt.
      </p>

      <p>
        <strong>Analiza potrzeby</strong> – kolejny z etapów lejka sprzedażowego, 
        podczas którego handlowcy mają
        dokładnie określić potrzeby klienta i&nbsp;uzyskać wszelkie informacje
        niezbędne do stworzenia adekwatnej oferty handlowej.
      </p>

      <p>
        <strong>Ofertowanie</strong> – handlowiec na tym etapie lejka przekazuje
        ofertę, a jego celem jest uzyskanie akceptacji klienta.
      </p>

      <p>
        <strong>Zamykanie</strong> – celem handlowca w ostatniej fazie lejka sprzedażowego jest
        zdobycie podpisanego dokumentu zamówienia.
      </p>

      <p>Tak właśnie może wyglądać procesowanie szans sprzedaży 
      skupione na kompleksowym wsparciu sprzedaży firmy.</p>

      <h2>Analizowanie lejka sprzedażowego</h2>
      <p style={{
          paddingBottom: 25,
        }}>
        Jak mierzyć lejek sprzedaży? Za pomocą raportów, w które wyposażony
        został system CRM. Przedstawimy tutaj przykładowe dwa z&nbsp;nich, ale w CRM można ich
        znaleźć (oraz stworzyć) znacznie więcej.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.konwersja]}
        alts={[
          "Raport “Kampanie sprzedażowe: Konwersja lejka sprzedaży” w systemie CRM",
        ]}
      />
       <p style={{
          paddingTop: 25,
        }}>
        Raport “Kampanie sprzedażowe: Konwersja lejka sprzedaży” służy do
        analizowania konwersji szans sprzedaży na poszczególnych etapach lejka sprzedażowego. 
        Wskazuje on, w której fazie poszczególne szanse odpadły 
        oraz ile z&nbsp;nich przeszło dalej. Jakie zastosowanie we wsparciu sprzedaży mogą mieć te
        dane? Na&nbsp;przykład wskazują wąskie gardła. Jeżeli większość szans
        sprzedaży została utracona w&nbsp;fazie ofertowania, może to oznaczać, że to właśnie ten
        etap lejka sprzedażowego wymaga wprowadzenia usprawnień.
      </p>

      <p>
        Raport “Kampanie sprzedaży: Lejek szans sprzedaży – ilościowy/wartościowy” 
        umożliwia przegląd liczby szans sprzedaży
        znajdujących się na każdym z etapów i podsumowuje ich wartości. Głównym
        przeznaczeniem tego narzędzia jest wsparcie sprzedaży w szacowaniu poziomu 
        przyszłych wyników sprzedażowych.
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz efektywnie wykorzystywać lejek sprzedażowy w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas i zyskaj dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wesprzeć swój proces sprzedaży
            </Link>
          </div>
          </div>
    </section>
      <br />
      <p>Przeczytaj więcej na temat systemu CRM:</p>
      <p>
        <ul>
          <li>
            <Link to="/wdrazanie-kultury-pracy-CRM">
              Wdrażanie kultury pracy z CRM w organizacji
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-zespolem-marketingu-crm">
              Zarządzanie pracą zespołu marketingu w&nbsp;systemie CRM
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-zespolem-rozproszonym">
              Zarządzanie rozproszonym zespołem sprzedażowym w czasie kryzysu
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Specrm;
